const envinroments = {
  "development" : {
    "api_url": "http://localhost:3000",
    "socket_url": "https://localhost:8080",
    //"socket_url": "http://45.77.177.201:8080"
    "domain": ""
  },
  "production" : {
    "api_url": "https://xejsipai.careerengine.co.kr",
    "socket_url": "http://45.77.177.201:8080",
    "domain": ".careerengine.co.kr"
  }
};

const env = envinroments[process.env.NODE_ENV || 'development'];

export default env;